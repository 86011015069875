// pdi - theme.js

const colors = {
  // Primary
  Indigo: '#07003D',
  Sky: '#00D2F4',
  Plum: '#A10A7D',
  Ocean: '#1226AA',
  // neutral
  ProductBaseWhite: '#FFFFFF',
  ProductBackgroundGray: '#F7F9FB',
  CoolGray: '#D9D8D6',
  ProductDarkSlate: '#686F6F',
  Granite: '#323E48',
  IndigoFooter: '#122048',
  // Secondary
  ProductSuccess: '#00C759',
  ProductAlert: '#C70F2B',
  ProductAction: '#0F65F0',
  Sienna: '#FF5100',
  Sunshine: '#FFB71B'
}

const theme = {
  dark: {
    backgroundColor: colors.IndigoFooter,
    color: colors.ProductBaseWhite
  },
  light: {
    backgroundColor: colors.ProductBackgroundGray,
    color: colors.Granite
  }
}

const pdiIconStyles = `
@font-face {
  font-family: 'pdi-icon';
  src: url('/fonts/pdi-icon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.pdi-icon { font-family: pdi-icon ! important; }

.pdi-icon.fa-home:before { content: "\\e937"; }
.pdi-icon.fa-user:before { content: "\\e960"}
.pdi-icon.fa-star:before { content: "\\e961"}
.pdi-icon.fa-folder:before { content: "\\e92b"}
.pdi-icon.fa-print:before { content: "\\e95f"}
.pdi-icon.fa-eye:before { content: "\\e97a"}
.pdi-icon.fa-pie-chart:before { content: "\\e90a"}
.pdi-icon.fa-truck:before { content: "\\e979"}
.pdi-icon.fa-tags:before { content: "\\e96f"}
.pdi-icon.fa-book:before { content: "\\e9a5"}
.pdi-icon.fa-calculator:before { content: "\\e9a6"}
.pdi-icon.fa-calendar-check-o:before { content: "\\e909"}
.pdi-icon.fa-credit-card:before { content: "\\e91c"}
.pdi-icon.fa-search:before { content: "\\e975"}
.pdi-icon.fa-cogs:before { content: "\\e977"}

`

// [qc('i.pdi-icon.fa-home'),
//   qc('i.pdi-icon.fa-user'),
//   qc('i.pdi-icon.fa-star'),
//   qc('i.pdi-icon.fa-folder'),
//   qc('i.pdi-icon.fa-print'),
//   qc('i.pdi-icon.fa-eye'),
//   qc('i.pdi-icon.fa-pie-chart'),
//   qc('i.pdi-icon.fa-truck'),
//   qc('i.pdi-icon.fa-tags'),
//   qc('i.pdi-icon.fa-book'),
//   qc('i.pdi-icon.fa-calculator'),
//   qc('i.pdi-icon.fa-calendar-check-o'),
//   qc('i.pdi-icon.fa-credit-card'),
//   qc('i.pdi-icon.fa-search'),
//   qc('i.pdi-icon.fa-cogs')]

// qc('span').css(theme.dark)

module.exports = { theme, colors, pdiIconStyles }
