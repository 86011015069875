// homepage-readyreport.js

const { __, $ajax } = window
const { qc } = common.cmp

const { io } = window

const setDownloaded = TaskID => {
  return $ajax({
    url: 'data/readyreport/method/downloaded',
    method: 'POST',
    data: { TaskID }
  }).then(res => {
    if (res.error) {
      var errMsg = typeof res.error === 'string' ? res.error : JSON.stringify(res.error)
      ow.popError(__('ERROR'), errMsg, 5000)
    }
  })
}

/**
 * finds div.download-notify and adds a button that opens readyreport-overview in click
 * @returns
 */
const downloadReportButton = () =>
  qc('button.k-button', __('Downlaod'))
    .attr({ type: 'button' })
    .on('click', () =>
      ow.windows.openView({
        name: 'Report Download',
        url: '/views/readyreport-overview.js',
        useExisting: true
      })
    )

const downloadTargetReport = d =>
  qc('button.green-button.k-button', __('Open'))
    .attr({ type: 'button' })
    .on('click', () =>
      $ajax({
        url: 'data/readyreport/method/download',
        method: 'POST',
        data: d
      }).then(res => {
        if (res.error) {
          var errMsg = typeof res.error === 'string' ? res.error : JSON.stringify(res.error)
          ow.popError(__('ERROR'), errMsg, 5000)
        }

        if ((res.ExportType === 0 || res.ExportType === 1) && res.ExportDataList) {
          ;(res.ExportDataList || []).forEach(function (x) {
            //ow.windows.downloadFile(x.exportFileName, x.data, 'octet/stream')
            ow.windows.openPdf(x.exportFileName, x.exportFileName, x.data)
          })
        }
        //ack downloaded
        setDownloaded(d.TaskID)
      })
    )

const notifyReady = r => {
  ow.popInfo(
    __('System'),
    [
      ow.htmlEncode(r.TaskName) + ' ' + __('ReportReadyDownload'),
      qc('div.download-notify', [downloadTargetReport(r), downloadReportButton()]).css({
        textAlign: 'right'
      })
    ],
    0 //dont close
  )
}

const processPoll = (r = {}) => {
  if (r.TaskStatus === 10) ow.popError('Error', r.TaskMessage, 5000)
  else if (r.TaskStatus === 9) notifyReady(r)

  //ack alert
  $ajax({
    method: 'POST',
    url: '/data/readyreport/method/notified',
    data: { TaskID: r.TaskID }
  }).catch(err => console.error(err))
}

const initReadyReport = () => io.socket.on('readyreport', processPoll)

// notifyReady()

module.exports = { initReadyReport }
