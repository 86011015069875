// homepage-tfa.js
// moved from two-factor-auth-view and restrcutured for Single Page App
const { qc, html } = common.cmp
const { goToLogin } = require('./homepage-login')

const styles = `
body {
  height: 100%;
  position: relative;
}
div.tfa {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 0.077em;
  border-radius: .39em;
  background-color: white;
  width: 40.895em;
  height: 23.919em;
  display: block;
  z-index: 10007;
  /* background: -webkit-linear-gradient(30deg, #134A6B 0%, #739ea8 50%, #cce2e7 100%); */
}
div.tfa-qrcode {
  width: 100%;
}  
div.tfa-qrcode>img {
  margin: 0 auto;
  display: block;
}
.hide {
  display: none;
}
body {
  padding: 0;
  margin: 0;
}
h3,
h4,
h5 { text-align: center; }
.k-window-title {
  text-align: center;
}
.tfa-footer {
  margin-top: 1.54em;
  width: 100%;
  float: right;
  display: block;
}
.tfaToken,
.tfaButton {
  width: 100%;
  align-items: center;
  text-align: center;
}
.tfaToken input {
  width: 50%;
  text-align: center;
}
#btnVerifyToken, .btn {
  margin-top: .77em;
}
#tfaQR {
  margin: 0 auto;
  margin-bottom: 1.54em;
}
.tfa-title {
  background-color: #307EBE;
  border-bottom: 0.154em solid #2e6a9c;
  height: 3.472em;
  width: 100%;
  border-top-left-radius: .39em;
  border-top-right-radius: .39em;
  margin-top: -0.077em;
  text-align: center;
  font-size: 1.1em;
  font-weight: 600;
  padding-top: .77em;
  color: white;
}
.tfa-body { padding: 0 .77em;  }
#btnVerifyToken:hover,
#btnVerifyToken:active {
  background-color: #1984c8;
  border-color: #1984c8;
}
#btnVerifyToken,.btn {
  color: #fff;
  background-color: #307EBE;
  border-color: #2e6a9c;
  box-shadow: 0.077em 0.154em 0.231em rgba(0, 0, 0, 0.25);
  width: 8.873em;
  padding-top: .285em;
  padding-bottom: .285em;
}
.k-window.tfa { padding-top: 0; }
.tfa ol { padding: .77em; }
`

const tfa = (__ = s => s) => `
<div class="k-window tfa">
  <style>${styles}</style>
  <div class="k-header tfa-title"><span>${__('TwoFactorAuthentication')}</span></div>
  <div class="tfa-body">
    <h3>${__('TwoFactorAuthenticationMessage1')}</h3>
    <div class="tfa-first hide">
      <h4>${__('TwoFactorAuthenticationMessage2')}</h4>
      <ol>
        <li><p>${__('GuideForDownloadingGoogleAuthenticator1')}</p></li>
        <li><p>${__('GuideForDownloadingGoogleAuthenticator2')}</p></li>
        <li><p>${__('GuideForDownloadingGoogleAuthenticator3')}</p></li>
        <li><p>${__('GuideForDownloadingGoogleAuthenticator4')}</p></li>
        <li class="guide-l5"><p>${__('GuideForDownloadingGoogleAuthenticator5')}</p></li>
        <li class="guide-l6"><p>${__('GuideForDownloadingGoogleAuthenticator6')}</p></li>
        <li class="guide-l7"><p>${__('GuideForDownloadingGoogleAuthenticator7')}</p></li>
        <li class="guide-l8"><p>${__('GuideForDownloadingGoogleAuthenticator8')}</p></li>
      </ol>
      <h4>${__('TwoFactorAuthenticationMessage3')}</h4>
    </div>
    <div class="tfa-normal">
      <h4>${__('PleaseContactAdminToReset')}</h4>
      <ol>
        <li class="login-guide-l1"><p>${__('GuideForAuthenticate1')}</p></li>
        <li class="login-guide-l2"><p>${__('GuideForAuthenticate2')}</p></li>
        <li class="login-guide-l3"><p>${__('GuideForAuthenticate3')}</p></li>
        <li class="login-guide-l4"><p>${__('GuideForAuthenticate4')}</p></li>
      </ol>      
      <hr>
    </div>
    <div class="tfa-qrcode hide">
      <img id="tfaQR" src="" alt="">
      <hr>
    </div>
    <div class="tfa-footer">
      <div class="tfaToken">
        <input id="tfaTokenInput" class="k-textbox" placeholder="${__('EnterToken')}">
      </div>
      <div class="tfaButton">
        <button class="k-button" id="btnVerifyToken">Verify</button>
        <button class="k-button btn" id="btnCancel">Cancel</button>
      </div>
    </div>
  </div>
</div>
`

const goToTfa = (dataUrl = '') => {
  const screenLock = qc('div#screenlock', html(tfa(__))).css({ opacity: '1' })
  screenLock.renderTo($('.main-content'))

  $('.workspace').html('')

  if (dataUrl !== '') {
    $('#tfaQR').attr('src', dataUrl)
    $('.tfa-first').removeClass('hide')
    $('.tfa-normal').addClass('hide')
    $('.tfa-qrcode').removeClass('hide')

    $('div.tfa').css('height', '54.8em')
  } else {
    $('.tfa-first').addClass('hide')
    $('.tfa-qrcode').addClass('hide')
    $('.tfa-normal').removeClass('hide')
  }

  if (!__('GuideForDownloadingGoogleAuthenticator5').trim()) $('.guide-l5').hide()
  if (!__('GuideForDownloadingGoogleAuthenticator6').trim()) $('.guide-l6').hide()
  if (!__('GuideForDownloadingGoogleAuthenticator7').trim()) $('.guide-l7').hide()
  if (!__('GuideForDownloadingGoogleAuthenticator8').trim()) $('.guide-l8').hide()
  if (!__('GuideForAuthenticate1').trim()) $('.login-guide-l1').hide()
  if (!__('GuideForAuthenticate2').trim()) $('.login-guide-l2').hide()
  if (!__('GuideForAuthenticate3').trim()) $('.login-guide-l3').hide()
  if (!__('GuideForAuthenticate4').trim()) $('.login-guide-l4').hide()

  $('#btnVerifyToken').on('click', e => {
    e.preventDefault()
    verifyToken()
  })

  $('#btnCancel').on('click', () => {
    goToLogin()
    window.logout()
  })
  $('#tfaTokenInput').on('keydown', e => (e.which === 13 ? verifyToken() : null))
  $('#tfaTokenInput')[0].focus()

  function verifyToken() {
    const token = $('.tfaToken > input').val()
    window
      .fetch('/verify-2fa', {
        method: 'PUT',
        dataType: 'json',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ token })
      })
      .then(r => r.json())
      .then(({ result, msg }) =>
        result ? window.location.reload() : ow.popError('Error', msg, 300)
      )
      .catch(err => {
        if (err.status === 403) ow.popError('Error', err.responseText, 300)
      })
  }
}

module.exports = { goToTfa }
