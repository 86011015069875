const { qc, html } = common.cmp
const { $find } = common.nojquery

let myGlobalMessages = []
let messageIcon

let hasMessages

const doSockets = io => {
  io.socket.on('globalmessage', info => processMessages(info))
}

/**
 * returns the right messageText for the lang *
 */
const msg = info => (info.SUPMessageTrans.find(x => x.Lang === ow.lang) || info).Message

const messageTicker = () => {
  $find(document.body, '.ticker-wrap').forEach(x => x.remove())
  const messages = myGlobalMessages.filter(x => x.Persistent).map(msg)
  if (messages.length) ticker(messages).renderTo($find(document.body, '.main-content'))
}

const ticker = messages => {
  let messageWidth = 0
  let messageHtml = messages.map((x, i) => {
    messageWidth += (x.length + 30) * 5
    return qc('span', i === 0 ? x : [' :: ', x])
  })

  return qc('div.ticker-wrap', [
    qc('div.ticker', messageHtml),
    html(`<style>

@keyframes ticker {
  from { left: ${window.innerWidth}px }
  to { left: -${messageWidth}px }
}

.ticker-wrap {
  position: absolute;
  bottom: 0;
  display: inline-block;
  white-space: nowrap;
  width: 100%; 
  overflow: hidden;
  background-color: #fff;
  font-size: 1em;
  color: #555; 
  padding-top: 2px;
  height: 1.6em;
  z-index: 999999
}

.ticker {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
  box-sizing: content-box;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 30s;
}

.ticker span { white-space: nowrap; }

</style>`)
  ])
}

const processMessages = newMsg => {
  if (newMsg) {
    myGlobalMessages = myGlobalMessages.filter(x => newMsg.MsgID !== x.MsgID)
    if (!newMsg.Deleted && new Date(newMsg.Expires) > new Date()) myGlobalMessages.push(newMsg)
    myGlobalMessages.sort((a, b) => b.SentDate - a.SentDate)
  }

  hasMessages(myGlobalMessages.length)

  const newReadIDs = []
  const readIDs = JSON.parse('[' + (localStorage.getItem('readMsgIDs') || '') + ']') // do we need to separate by UserID ????
  localStorage.setItem('readMsgIDs', '')

  // update read flags
  myGlobalMessages.forEach(m => {
    if (readIDs.indexOf(m.MsgID) + 1) {
      newReadIDs.push(m.MsgID)
      m.read = true
    } else {
      ow.popInfo(__('Support Message'), msg(m), 0)
      if (m.MsgID) newReadIDs.push(m.MsgID)
      m.read = true
    }
  })
  localStorage.setItem('readMsgIDs', newReadIDs.join(','))

  messageTicker()
}

const makeMessageIcon = ow => {
  const icon = qc('i.pdi-icon-Mail').css({
    display: 'inline-block',
    float: 'left',
    marginRight: '0.5em',
    fontSize: '1.5rem',
    lineHeight: 'inherit'
  })

  hasMessages = hasOrNot => {
    icon.css({ opacity: hasOrNot ? '1' : '0.33' })
    icon.renderAttrs()
  }

  messageIcon = qc('nav.global-messages', qc('a', qc('label', icon))).on('click', () => {
    ow.windows.openView({
      name: __('Messages'),
      url: 'views/globalmessage-list.js',
      useExisting: true,
      width: 500,
      height: 600
    })
  })

  //  messageIcon.renderTo($find(document.body, '.header-block')[0])
}

// Global Messaging
const globalMessaging = (io, ow) => {
  doSockets(io)

  makeMessageIcon(ow)

  // $ajax({
  //   includeTimestamp: true,
  //   url: '/data/globalmessage/lookup/forme'
  // }).then(r => {
  //   myGlobalMessages = r.data
  //   processMessages()
  // })
}

module.exports = { globalMessaging }
