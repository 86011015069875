const hpLogin = require('./homepage-login')
const { qc } = common.cmp

const isScreenLocked = () => sessionStorage.getItem('screenlock') === '1'

const unlockScreen = () => sessionStorage.removeItem('screenlock')

const lockScreen = () => {
  sessionStorage.setItem('screenlock', '1') // this ensures opening child page also screenlocks
  const { UserID, DBID } = ow0
  hpLogin.goToLogin({ UserID, DBID })
}

const startIdleChecks = idleMin => {
  idleMin = parseInt(idleMin)

  // Reopen screen lock if page refresh
  if (isScreenLocked()) lockScreen()

  let lastActionTime = new Date().getTime()

  let actionDetectedThisPeriod = true
  const checkIdle = () => {
    if (actionDetectedThisPeriod) lastActionTime = new Date().getTime()
    actionDetectedThisPeriod = false
    if (new Date().getTime() > lastActionTime + idleMin) if (!isScreenLocked()) lockScreen()
    setTimeout(checkIdle, 5000) // check every 5 seconds
  }

  checkIdle()

  qc(document.body).on('mousemove keydown', () => (actionDetectedThisPeriod = true), true)
}

module.exports = { startIdleChecks, isScreenLocked, unlockScreen, lockScreen }
