const { $ajax, common } = window
const { qc, html } = common.cmp
const { $find } = common.nojquery
const { goToLogin } = require('./homepage-login')
const { pdiLogo } = require('./pdi-logo')

const styles = () =>
  html(`
<style>
.domain-title { text-align: center; margin: 1em; }
.domain-content { 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;  
  background: transparent;  
  padding-left: 2em;  
  padding-right: 2em;
  max-height: 160px;
  overflow: auto;      
}
.domain-content .item {  
  background: transparent;
  color: rgb(118, 118, 118);
  width: 48%;
  align-items: center;
  text-align: center;
  padding: 1em;
  margin-bottom: 2px;
  margin-right: 1px;
  border: solid rgb(118, 118, 118) .077em;
  font-weight: bold;
  font-size: 12.96px;      
}
.domain-content .item.selected { 
  border-left: solid 5px;
}
.domain-content .item:hover { 
  background: rgb(118, 118, 118);
  color: #fff;
}

#SelectDomainForm { background: #F3F6F8; box-shadow: .077em .154em .231em rgba(0,0,0,0.25); border: 0 none !important; border-radius: .231em; }
#SelectDomainForm p { color: #848484; text-align: center; padding-bottom: 1.54em;}
#SelectDomainForm a { text-decoration: underline; }
.ssodomainButton {
  width: 100%;
  align-items: center;
  text-align: center;
  margin: 1em auto;  
}
</style>
`)

const sessionid = sessionStorage.getItem('sessionid')

const connectDomain = domain =>
  $ajax({
    url: '/sso/verify-domain',
    method: 'PUT',
    data: { domain },
    headers: { sessionid }
  })
    .then(r => {
      if (r.result) {
        localStorage.removeItem('ssodomain')
        window.location.reload()
      } else ow.popError('Error', r.errMsg || 'Error', 5000)
    })
    .catch(err => {
      if (err.status === 403) ow.popError('Error', err.responseText, 5000)
    })

const goToDomain = async () => {
  $find('.workspace')[0].innerHTML = ''

  const screenLock = qc('div#screenlock').css({ opacity: '1' })
  screenLock.renderTo($find('.main-content')[0])

  const domains = await $ajax('/sso/domains').catch(err => {
    if (err.status === 403) ow.popError('Error', err.responseText, 5000)
    return []
  })

  screenLock.kids(
    qc('form#SelectDomainForm.login.k-window').kids([
      styles(),
      qc('div.logo', pdiLogo()).css({ padding: '1em' }),
      qc('div.domain-title', qc('h3', __('SelectDomain'))),
      qc(
        'div.domain-content',
        qc(
          'select#domains.k-dropdown',
          domains.map(domain => qc('option', domain.Name).attr({ value: domain.DBID }))
        )
          .attr({ name: 'domains' })
          .css({ width: '100%', padding: '1em' })
          .on('init', (e, el) => el.focus())
      ),
      qc('div.ssodomainButton', [
        qc('button.k-button#btnSelectDomain', __('LoginSubmitButton')).on('click', e => {
          e.preventDefault()
          connectDomain($find('#domains')[0].value)
        }),
        qc('button.k-button.btn#btnCancel', __('Cancel')).on('click', e => {
          e.preventDefault()
          sessionStorage.removeItem('sessionid')
          localStorage.removeItem('ssodomain')
          screenLock.el.remove()
          goToLogin()
        })
      ])
    ])
  )

  return domains
}

module.exports = { goToDomain }
