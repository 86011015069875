// homepage-ping.js
// This provides client-side connectivity management
// and replaces some of the sockets functions
const { $ajax, common } = window
const { nojquery } = common
const { $find } = nojquery
const { qc } = common.cmp

const state = {
  live: undefined
}

const app = {
  state
}

// replace io.socket functions
const io = {
  socket: {
    on(msg, handler) {
      console.log('Adding broadcast message handler', msg)
      window.addEventListener('orbis-broadcast', e => {
        if (msg === e.data.topic) {
          const d = e.data.topic === 'long-running' ? e.data : e.data.data || e.data
          handler(d)
        }
      })
    },
    off(topic, handler) {
      window.removeEventListener('orbis-broadcast', handler)
    },
    fire(msg) {
      const e = new CustomEvent('orbis-broadcast', { msg })
      e.data = msg
      dispatchEvent(e)
    },
    get(url, data, cb) {
      return window
        .fetch(url, { data })
        .then(r => r.text())
        .then(cb)
    },
    post(url, data, cb) {
      return window
        .fetch(url, { method: 'post' })
        .then(r => r.text())
        .then(cb)
    },
    put(url, data, cb) {
      return window
        .fetch(url, { method: 'put' })
        .then(r => r.text())
        .then(cb)
    }
  }
}

io.socket.on('alert', data => ow.popInfo('Broadcast Message', data.msg, 0))

io.socket.on('long-running', (...args) =>
  $find('.long-running-subscriber').forEach(scope => qc(scope).trigger('long-running', ...args))
)

// todo: create each time and attach,
// don't leave in the DOM all the time
const overlay = v => {
  var el = document.getElementById('overlay')
  if (el) el.style.visibility = v ? 'visible' : 'hidden'
}

// this is a object that needs to be send to the server each ping
let pingState
try {
  pingState = JSON.parse(sessionStorage.getItem('pingState') || '{}')
} catch (err) {
  console.warn('Error loading pingState from localStorage')
  pingState = {}
}

// const w = new Worker('wait-worker.js')
// w.onmessage = () => waiting()
// w.onerror = err => console.error('WebWorker failed!', err)
// let waiting
// const wait = async (ms = 500) =>
//   new Promise(resolve => {
//     waiting = resolve
//     w.postMessage(ms)
//   })

const startPing = () => {
  let pingInterval = 1000

  return $ajax({ url: '/ping', method: 'put', data: { live: app.state?.live, pingState } })
    .then(res => {
      if (res.pingState) {
        pingState = res.pingState
        sessionStorage.setItem('pingState', JSON.stringify(pingState))
      }
      if (!res || res.live !== app.state.live) {
        app.state.live = res.live
        overlay(res.live ? 0 : 1)
      }

      if (!res) return

      pingInterval = !res.live ? 500 : res.UserID ? 500 : 3000
      if (res.broadcasts) res.broadcasts.forEach(msg => io.socket.fire(msg))
      return res
    })
    .catch(err => {
      app.state.live = false
      overlay(1)
      console.error('Ping Error', err)
      throw err
    })
    .finally(
      () => setTimeout(startPing, pingInterval) // wait(pingInterval).then(startPing)
    )
}

module.exports = { startPing, state, io }
