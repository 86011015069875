const { qc } = common.cmp

module.exports.pdiLogo = () =>
  qc(
    `<svg id="PDI-Logo-White" xmlns="http://www.w3.org/2000/svg" width="68" height="17.213" viewBox="0 0 68 17.213" >
  <g id="Artwork_7" data-name="Artwork 7" transform="translate(34 8.606)" >
  <g id="Group_10235" data-name="Group 10235" transform="translate(-34 -8.606)" >
  <g id="Group_10234" data-name="Group 10234" >
  <g id="Group_10232" data-name="Group 10232" transform="translate(22.586)" >
  <g id="Group_10231" data-name="Group 10231" >
  <path id="Path_22840" data-name="Path 22840" d="M134.077,5.865c0,3.952-2.423,6.094-6.757,6.094h-6.273v5.253h-4.31V0H127.32C131.655,0,134.077,1.4,134.077,5.865Zm-4.411,0c0-1.5-.969-1.887-2.626-1.887h-5.993V8.084h5.993C128.7,8.084,129.666,7.395,129.666,5.865Z" transform="translate(-116.737)" fill="#fff" ></path>
  <path id="Path_22841" data-name="Path 22841" d="M235.616,8.211v.765c0,5.406-3.111,8.237-9.588,8.237h-9.92V0h9.92C232.5,0,235.616,2.856,235.616,8.211Zm-4.437-.026c0-3.111-1.887-4.105-5.151-4.105H220.52v9.052h5.508c3.264,0,5.151-.969,5.151-4.131Z" transform="translate(-196.882)" fill="#fff" ></path>
  <path id="Path_22842" data-name="Path 22842" d="M328.66,0h4.411V17.213H328.66Z" transform="translate(-287.658)" fill="#fff" ></path></g></g><g id="Group_10233" data-name="Group 10233" transform="translate(0 0)" >
  <path id="Path_22843" data-name="Path 22843" d="M13.461,3.751H5.95V0L0,5.95V9.7H7.512v7.512h3.751l5.95-5.95H13.461Z" transform="translate(0 0)" fill="#00d2f4" ></path>
  <path id="Path_22844" data-name="Path 22844" d="M42.014,11.263H38.262V3.751H30.751V0H42.014Z" transform="translate(-24.801 0)" fill="#fff" >
  </path>
  </g>
  </g>
  </g>
  </g>
  </svg>`
  )
